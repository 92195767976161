.aboutCard,
.aboutHero {
    width: 80%;
    border-radius: var(--medium)
}

.aboutCard,
.aboutHeroContainer {
    display: grid;
    padding: var(--xtra-large)
}

.aboutCardTagline,
.coreValueTitle {
    font-size: var(--fs-heading2);
    font-weight: var(--fw-medium)
}

.aboutCardRightPane .intro,
.aboutCardTagline,
.coreValueTitle,
.name,
.tag {
    font-weight: var(--fw-medium)
}

.aboutHero,
.nameTag,
.ourTeamTag {
    color: var(--primary-clr-light)
}

.aboutUsContainer {
    display: grid;
    gap: var(--xtra-xtra-large);
    margin: auto
}

.aboutHero {
    margin: auto;
    overflow: hidden
}

.aboutHeroContainer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .3)), url(../images/aboutHero.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    height: 100%;
    gap: var(--medium);
    place-content: center;
    margin: auto;
    padding: var(--xtra-xtra-large);
}

.constitutionBtn {
    justify-content: center;
    align-items: center;

    margin: auto;
}

.constitutionBtn:hover {
    color: var(--primary-clr-light);
    background-color: var(--secondary-clr-btn);
}

.aboutCard,
.coreValueContainer {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    margin: auto
}

.aboutHeroContainer h3 {
    font-size: var(--fs-heading1)
}

.aboutHeroContainer h3,
.aboutHeroContainer p {
    max-width: 50%;
    text-align: center;
    margin: auto
}

.aboutCard {
    gap: var(--xtra-xtra-large);
    background-color: var(--alt-clr)
}

.aboutCardLeftPane,
.aboutCardRightPane {
    display: flex;
    flex-direction: column;
    gap: var(--medium)
}

.tag {
    width: fit-content;
    height: fit-content;
    padding: var(--xtra-small);
    border-radius: var(--small)
}

.coreValueImgContainer {
    max-height: 350px;
}

.coreValueImgContainer img {
    object-fit: cover;
    object-position: center;
}

.coreValueImgContainer,
.teamCard {
    border-radius: var(--medium);
    overflow: hidden
}

.aboutCardTag {
    background-color: var(--primary-clr-light)
}

.aboutCardTagline {
    max-width: 90%
}

.coreValuesContainer {
    width: 80%;
    display: grid;
    gap: var(--xtra-xtra-large)
}

.coreValueContainer {
    display: grid;
    gap: var(--xtra-large);
    align-items: center
}

.coreValueTexts,
.teamContainer {
    gap: var(--medium);
    display: grid
}

.coreValueContainer.reverse {
    grid-template-columns: 1fr 1fr
}

.coreValueContainer.reverse .coreValueImgContainer {
    order: 2
}

.teamContainer {
    width: 80%;
    margin: auto
}

.ourTeamTag {
    background-color: var(--primary-clr-dark)
}

.teamCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    justify-content: space-between;
    align-items: center;
    gap: var(--xtra-large)
}

.teamCard {
    height: 400px;
    position: relative
}

.profileImg {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.nameTag {
    display: grid;
    width: 90%;
    position: absolute;
    bottom: var(--medium);
    left: 5%;
    right: 5%;
    padding: var(--small);
    background: rgba(255, 255, 255, .1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, .3);
    transition: scale .2s ease-in-out
}